export const universities = [
  'Air Force Institute of Technology',
  'Alabama A&M University',
  'Arizona State University',
  'Brigham Young University',
  'Georgia Institute of Technology',
  'Indiana University',
  'Metropolitan State University',
  'Naval Post Graduate School',
  'New Mexico State University',
  'North Carolina A&T University',
  'Purdue University',
  'Saint Louis University',
  'Savannah State University',
  'Tennessee State University',
  'Texas A&M University',
  'Tougaloo College',
  'University of Maryland',
  'University of Minnesota (Twin Cities)',
  'University of Saint Thomas',
  'University of Tennessee (Chattanooga)',
  'University of Texas El Paso',
  'Vanderbilt University',
]

export const relationships = {
  PATHFINDER_DEPLOYMENT: {
    color: '#FF0000',
    links: [['Arizona State University', 'Vanderbilt University']],
  },
  HBCU_NETWORK: {
    color: '#00FF00',
    links: [
      ['Alabama A&M University', 'Arizona State University'],
      ['Alabama A&M University', 'North Carolina A&T University'],
      ['Alabama A&M University', 'Purdue University'],
      ['Alabama A&M University', 'Savannah State University'],
      ['Alabama A&M University', 'Tennessee State University'],
      ['Alabama A&M University', 'Tougaloo College'],
    ],
  },
  NSREC_2024: {
    color: '#0000FF',
    links: [
      ['Arizona State University', 'Brigham Young University'],
      ['Arizona State University', 'Georgia Institute of Technology'],
      ['Arizona State University', 'Indiana University'],
      ['Arizona State University', 'Purdue University'],
      ['Arizona State University', 'University of Tennessee (Chattanooga)'],
      ['Arizona State University', 'Vanderbilt University'],
    ],
  },
  SCALE_PI_SYMPOSIUM: {
    color: '#FFA500',
    links: [
      ['Air Force Institute of Technology', 'Arizona State University'],
      ['Air Force Institute of Technology', 'Brigham Young University'],
      ['Air Force Institute of Technology', 'Georgia Institute of Technology'],
      ['Air Force Institute of Technology', 'Indiana University'],
      ['Air Force Institute of Technology', 'Metropolitan State University'],
      ['Air Force Institute of Technology', 'Naval Post Graduate School'],
      ['Air Force Institute of Technology', 'New Mexico State University'],
      ['Air Force Institute of Technology', 'Purdue University'],
      ['Air Force Institute of Technology', 'Saint Louis University'],
      ['Air Force Institute of Technology', 'Tougaloo College'],
      ['Air Force Institute of Technology', 'University of Maryland'],
      ['Air Force Institute of Technology', 'University of Minnesota (Twin Cities)'],
      ['Air Force Institute of Technology', 'University of Saint Thomas'],
      ['Air Force Institute of Technology', 'University of Tennessee (Chattanooga)'],
      ['Air Force Institute of Technology', 'Vanderbilt University'],
    ],
  },
  NANOHUB_COURSE_SHARING: {
    color: '#800080',
    links: [
      ['Arizona State University', 'Brigham Young University'],
      ['Arizona State University', 'Indiana University'],
      ['Arizona State University', 'Purdue University'],
      ['Arizona State University', 'Saint Louis University'],
      ['Arizona State University', 'Texas A&M University'],
      ['Arizona State University', 'Vanderbilt University'],
    ],
  },
  NANOHUB_COURSE_REUSE: {
    color: '#4B0082',
    links: [
      ['Brigham Young University', 'Indiana University'],
      ['Brigham Young University', 'Purdue University'],
      ['Indiana University', 'Purdue University'],
      ['Purdue University', 'University of Minnesota (Twin Cities)'],
      ['Purdue University', 'University of Saint Thomas'],
    ],
  },
  WEEKLY_RH_SEMINAR: {
    color: '#008000',
    links: [
      ['Arizona State University', 'Brigham Young University'],
      ['Arizona State University', 'Georgia Institute of Technology'],
      ['Arizona State University', 'Indiana University'],
      ['Arizona State University', 'Purdue University'],
      ['Arizona State University', 'Saint Louis University'],
      ['Arizona State University', 'University of Minnesota (Twin Cities)'],
      ['Arizona State University', 'University of Tennessee (Chattanooga)'],
      ['Arizona State University', 'Vanderbilt University'],
    ],
  },
  MINNESOTA_RH_KICKOFF: {
    color: '#FF4500',
    links: [
      ['Metropolitan State University', 'Purdue University'],
      ['Metropolitan State University', 'University of Minnesota (Twin Cities)'],
      ['Metropolitan State University', 'University of Saint Thomas'],
      ['Metropolitan State University', 'Vanderbilt University'],
      ['Purdue University', 'University of Minnesota (Twin Cities)'],
      ['Purdue University', 'University of Saint Thomas'],
      ['Purdue University', 'Vanderbilt University'],
      ['University of Minnesota (Twin Cities)', 'University of Saint Thomas'],
      ['University of Minnesota (Twin Cities)', 'Vanderbilt University'],
      ['University of Saint Thomas', 'Vanderbilt University'],
    ],
  },
  IU_RH_RESEARCH_POSTER: {
    color: '#FF1493',
    links: [
      ['Indiana University', 'Purdue University'],
      ['Indiana University', 'Vanderbilt University'],
      ['Purdue University', 'Vanderbilt University'],
    ],
  },
}
