import {BrowserRouter, Route, Routes, To, useLocation, useNavigate} from 'react-router-dom'
import Login from 'routes/public/login'
import Landing from 'routes/public/landing'
import Health from 'routes/public/health'
import {useAuthProvider} from 'auth/AuthProvider'
import Home from 'routes/private/home'
import PublicRoute from 'routes/public'
import PrivateRoute from 'routes/private'
import Terms from 'routes/public/terms'
import Privacy from 'routes/public/privacy'
import Sandbox from 'routes/public/sandbox'
import Profile from 'routes/public/profile'
import GraphDemo from 'routes/public/GraphDemo'
import GraphDemo2 from 'routes/public/GraphDemo2'

export default function AppRoutes() {
  const {currentUser} = useAuthProvider()
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            {currentUser ? (
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            ) : (
              <PublicRoute>
                <Landing />
              </PublicRoute>
            )}
          </>
        }
      />
      <Route
        path='/sandbox'
        element={
          <PublicRoute>
            <Sandbox />
          </PublicRoute>
        }
      />
      <Route
        path='/demo'
        element={
          <PublicRoute>
            <GraphDemo />
          </PublicRoute>
        }
      />
      <Route
        path='/demo2'
        element={
          <PublicRoute>
            <GraphDemo2 />
          </PublicRoute>
        }
      />

      <Route
        path='/privacy'
        element={
          <PublicRoute>
            <Privacy />
          </PublicRoute>
        }
      />
      <Route
        path='/terms'
        element={
          <PublicRoute>
            <Terms />
          </PublicRoute>
        }
      />

      <Route
        path='/login'
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path='/profile'
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />

      <Route
        path='/health'
        element={
          <PublicRoute>
            <Health />
          </PublicRoute>
        }
      />
      <Route
        path='/terms'
        element={
          <PublicRoute>
            <Terms />
          </PublicRoute>
        }
      />
      <Route
        path='/privacy'
        element={
          <PublicRoute>
            <Privacy />
          </PublicRoute>
        }
      />
    </Routes>
  )
}
