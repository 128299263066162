import React from 'react'
//import { styled } from "@mui/material/styles";
import CopyrightIcon from '@mui/icons-material/Copyright'
import Typography from '@mui/material/Typography'

// const Root = styled("div")(({ theme }) => ({
//   width: "100%",
//   ...theme.typography.body2,
//   "& > :not(style) + :not(style)": {
//     marginTop: theme.spacing(2),
//   },
// }));

// syle i had on parent div to align the image
// style={{
//   display: "flex",
//   flexDirection: "column",
//   alignContent: "center",
//   flexGrow: 1,
//   height: "fit-content",
//   backgroundColor: "#232323",
//   flexWrap: "wrap",
//   alignItems: "center",
//   color: "#3EC1E8",
//   bottom: 0,
//   textAlign: "center",
// }}

export default function Footer(): React.ReactElement {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#C4C4C4',
        color: '#000000',
        bottom: 0,
        textAlign: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '1.5rem',
          minHeight: '60px',
          fontFamily: 'Alatsi',
        }}
      >
        <Typography alignItems={'center'} display={'flex'} justifyContent={'space-evenly'}>
          {' '}
          <CopyrightIcon />
          <span>Third Coast Federal 2024</span>
        </Typography>
      </div>
    </div>
  )
}
