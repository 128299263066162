import CheckIcon from '@mui/icons-material/Check'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import {green, grey} from '@mui/material/colors'
import MemoryIcon from '@mui/icons-material/Memory'
import * as React from 'react'

interface Props {
  loading: boolean
  success: boolean
  upperText?: string
  lowerText?: string
}
export default function InteractiveLoading({loading, success, upperText, lowerText}: Props) {
  const timer = React.useRef<number>()

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  }

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  return (
    <>
      {loading ? (
        <Box
          sx={{
            opacity: 0.75,
            top: 0,
            position: 'absolute',
            width: '100%',
            display: 'flex',
            left: 0,
            height: '100vh',
          }}
          display={'absolute'}
          justifyContent={'center'}
          textAlign={'center'}
          alignItems={'center'}
          bgcolor={grey[900]}
        >
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant={'h6'} color={'white'}>
              {upperText ? upperText : 'loading...'}
            </Typography>
            <Box display={'flex'}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{m: 1, position: 'relative'}}>
                  <Fab aria-label='save' color='primary' sx={buttonSx}>
                    {!loading ? (
                      <CheckIcon sx={{fontSize: '36px'}} />
                    ) : (
                      <MemoryIcon fontSize='large' />
                    )}
                  </Fab>
                  {loading && (
                    <Box>
                      <CircularProgress
                        size={68}
                        sx={{
                          color: green[500],
                          position: 'absolute',
                          top: -6,
                          left: -6,
                          zIndex: 1,
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Typography variant={'body1'} color={'white'}>
              {lowerText ? lowerText : ''}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}
