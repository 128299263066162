import React, {useState} from 'react'
import MainLayout from 'layouts/MainLayout'
import LandingBar from 'layouts/LandingBar'
import Footer from 'layouts/footer'
import Box from '@mui/material/Box'
import MRTExperience from './playground_components/MRTExperience'
import MRTSimpleExample from './playground_components/MRTSimpleExample'
import {IExperience} from 'interfaces/IExperience'
import axios from 'axios'
import {apiHost} from 'config/apiConfig'

export default function Sandbox(): React.ReactElement {
  //Mock Data
  const responseGetDataGridExperience = (response: any) => {
    axios({
      method: 'GET',
      url: `${apiHost}/api/data/experiences`,
    })
      .then(async (apiResponse: any) => {
        setData(apiResponse.data as unknown as IExperience[])
      })
      .catch((err: any) => {
        return undefined
        console.log(err)
      })
  }

  const [data, setData] = useState<any>(responseGetDataGridExperience)
  const dataC = (data as unknown as IExperience[]) ?? []
  return (
    <MainLayout header={<LandingBar />} footer={<Footer />}>
      <h2>Hello Sandbox</h2>
      <Box>{dataC?.length ? <MRTExperience data={dataC} /> : <></>}</Box>
    </MainLayout>
  )
}
