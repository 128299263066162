import grey from '@mui/material/colors/grey'
import Typography from '@mui/material/Typography'

export default function Terms() {
  return (
    <div style={{height: '100vh', textAlign: 'center', padding: '15px'}}>
      <Typography variant={'h4'} color={grey[700]} p={2}>
        TCF Terms of Service
      </Typography>
      <iframe
        src={`${process.env.PUBLIC_URL}/TCFTerms.html`}
        title='TCF Terms of Service'
        width='90%'
        height='90%'
      />
    </div>
  )
}
