//@ts-nocheck
import Box from '@mui/material/Box'
import React, {useEffect, useRef, useState} from 'react'
import * as d3 from 'd3'
import {universities, relationships} from './constants/networkData'

export default function NetworkGraph() {
  const svgRef = useRef(null)
  const [hoveredNode, setHoveredNode] = useState(null)
  const [dimensions, setDimensions] = useState({width: 0, height: 0})
  const [position, setPosition] = useState({x: 20, y: 20})
  const [dragState, setDragState] = useState({dragging: false, initialX: 0, initialY: 0})

  const handleMouseDown = (e) => {
    e.preventDefault()
    setDragState({
      dragging: true,
      initialX: e.clientX - position.x,
      initialY: e.clientY - position.y,
    })
  }

  useEffect(() => {
    if (dragState.dragging) {
      const handleMouseMove = (e) => {
        e.preventDefault()
        setPosition({
          x: Math.min(Math.max(0, e.clientX - dragState.initialX), dimensions.width),
          y: Math.min(Math.max(0, e.clientY - dragState.initialY), dimensions.height),
        })
      }

      const handleMouseUp = () => {
        setDragState({dragging: false, initialX: 0, initialY: 0})
      }

      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)

      return () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }
    }
  }, [dragState, dimensions])

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth - 10,
        height: window.innerHeight - 135,
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!dimensions.width || !dimensions.height || !svgRef.current) return

    const svg = d3.select(svgRef.current)
    svg.selectAll('*').remove()

    // Define arrow markers for each relationship type
    const defs = svg.append('defs')
    Object.entries(relationships).forEach(([type, {color}]) => {
      defs
        .append('marker')
        .attr('id', `arrow-${type}`)
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 8) // Adjusted for edge connection
        .attr('refY', 0)
        .attr('markerWidth', 6)
        .attr('markerHeight', 6)
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M0,-5L10,0L0,5')
        .attr('fill', color)
    })

    const calculateConnectionsAndDominantColor = (universityName) => {
      const relationshipCounts = new Map()
      let dominantColor = '#e5e7eb'
      let maxRelationships = 0

      const directConnections = new Set()

      Object.entries(relationships).forEach(([type, {color, links}]) => {
        let typeCount = 0

        links.forEach(([source, target]) => {
          if (source === universityName || target === universityName) {
            directConnections.add(JSON.stringify([source, target].sort()))
            typeCount++
          }
        })

        if (typeCount > 0) {
          relationshipCounts.set(type, typeCount)
          if (typeCount > maxRelationships) {
            maxRelationships = typeCount
            dominantColor = color
          }
        }
      })

      return {
        connections: directConnections.size,
        fillColor:
          directConnections.size > 0 ? d3.color(dominantColor).copy({opacity: 0.2}) : '#f3f4f6',
      }
    }

    const nodes = universities.map((name) => {
      const {connections, fillColor} = calculateConnectionsAndDominantColor(name)
      return {
        id: name,
        connections,
        fillColor,
      }
    })

    const links = Object.entries(relationships).flatMap(([type, {color, links}]) =>
      links.map(([source, target]) => ({
        source,
        target,
        color,
        type,
      }))
    )

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        'link',
        d3
          .forceLink(links)
          .id((d) => d.id)
          .distance(120)
      )
      .force('charge', d3.forceManyBody().strength(-800))
      .force('center', d3.forceCenter(dimensions.width / 2, dimensions.height / 2))
      .force('collide', d3.forceCollide().radius(50))

    const link = svg
      .append('g')
      .selectAll('line')
      .data(links)
      .join('line')
      .attr('stroke', (d) => d.color)
      .attr('stroke-width', 2)
      .attr('marker-end', (d) => `url(#arrow-${d.type})`)

    const node = svg
      .append('g')
      .selectAll('g')
      .data(nodes)
      .join('g')
      .on('mouseover', (event, d) => {
        const rect = event.currentTarget.getBoundingClientRect()
        setHoveredNode({
          name: d.id,
          count: d.connections,
          x: rect.x,
          y: rect.y,
        })
      })
      .on('mouseout', () => setHoveredNode(null))
      .call(
        d3
          .drag()
          .on('start', (event) => {
            if (!event.active) simulation.alphaTarget(0.3).restart()
            event.subject.fx = event.subject.x
            event.subject.fy = event.subject.y
          })
          .on('drag', (event) => {
            event.subject.fx = Math.min(Math.max(50, event.x), dimensions.width - 50)
            event.subject.fy = Math.min(Math.max(50, event.y), dimensions.height - 50)
          })
          .on('end', (event) => {
            if (!event.active) simulation.alphaTarget(0)
          })
      )

    node
      .append('circle')
      .attr('r', 45)
      .attr('fill', (d) => d.fillColor)
      .attr('stroke', '#666')
      .attr('stroke-width', 2)

    node
      .append('text')
      .attr('dy', '0.3em')
      .attr('text-anchor', 'middle')
      .attr('font-size', '10px')
      .attr('font-family', 'Arial')
      .each(function (d) {
        const words = d.id.split(' ')
        const el = d3.select(this)
        const lineHeight = 1.1
        const totalHeight = (words.length - 1) * lineHeight

        words.forEach((word, i) => {
          el.append('tspan')
            .text(word)
            .attr('x', 0)
            .attr('dy', i === 0 ? -totalHeight / 2 + 'em' : lineHeight + 'em')
        })
      })

    simulation.on('tick', () => {
      nodes.forEach((node) => {
        node.x = Math.max(50, Math.min(dimensions.width - 50, node.x))
        node.y = Math.max(50, Math.min(dimensions.height - 50, node.y))
      })

      // Calculate the points where the line should connect to the circles
      link.each(function (d) {
        const radius = 45 // Match circle radius
        const sourceX = d.source.x
        const sourceY = d.source.y
        const targetX = d.target.x
        const targetY = d.target.y

        // Calculate the angle and distance between nodes
        const dx = targetX - sourceX
        const dy = targetY - sourceY
        const distance = Math.sqrt(dx * dx + dy * dy)

        if (distance === 0) return // Skip if nodes are at the same position

        // Calculate the points on the circle circumference
        const sourceRatio = radius / distance
        const targetRatio = radius / distance

        const newSourceX = sourceX + dx * sourceRatio
        const newSourceY = sourceY + dy * sourceRatio
        const newTargetX = targetX - dx * targetRatio
        const newTargetY = targetY - dy * targetRatio

        // Update the line coordinates
        d3.select(this)
          .attr('x1', newSourceX)
          .attr('y1', newSourceY)
          .attr('x2', newTargetX)
          .attr('y2', newTargetY)
      })

      node.attr('transform', (d) => `translate(${d.x},${d.y})`)
    })

    return () => simulation.stop()
  }, [dimensions])

  return (
    <Box display={'flex'}>
      <svg
        ref={svgRef}
        width={dimensions.width}
        height={dimensions.height}
        style={{background: '#fff'}}
      />
      <div
        onMouseDown={handleMouseDown}
        style={{
          position: 'absolute',
          left: `${position.x}px`,
          top: `${position.y + 75}px`,
          zIndex: 1000,
          background: 'white',
          padding: '1rem',
          borderRadius: '0.5rem',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          border: '1px solid #e5e7eb',
          cursor: dragState.dragging ? 'grabbing' : 'grab',
          userSelect: 'none',
          touchAction: 'none',
        }}
      >
        <h3 style={{fontWeight: 'bold', marginBottom: '0.75rem', fontSize: '0.875rem'}}>
          Partnership Types
        </h3>
        <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
          {Object.entries(relationships).map(([name, {color}]) => (
            <div key={name} style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
              <svg height='2' width='24'>
                <line x1='0' y1='1' x2='24' y2='1' stroke={color} strokeWidth='2' />
              </svg>
              <span style={{fontSize: '0.875rem'}}>{name.replace(/_/g, ' ')}</span>
            </div>
          ))}
        </div>
      </div>
      {hoveredNode && (
        <div
          style={{
            position: 'absolute',
            left: hoveredNode.x - 45,
            top: hoveredNode.y - 65,
            background: 'white',
            padding: '0.5rem',
            borderRadius: '0.375rem',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            border: '1px solid #e5e7eb',
            zIndex: 1000,
          }}
        >
          <div style={{fontSize: '0.875rem', fontWeight: '500'}}>{hoveredNode.name}</div>
          <div style={{fontSize: '0.75rem'}}>Partnership Activities: {hoveredNode.count}</div>
        </div>
      )}
    </Box>
  )
}
