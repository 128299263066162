import {useMemo, useState} from 'react'

//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table'

//Material UI Imports
import {Box, Button, ListItemIcon, MenuItem, Typography, lighten} from '@mui/material'

//Icons Imports
import {AccountCircle, Send} from '@mui/icons-material'

//Date Picker Imports - these should just be in your Context Provider
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {IExperience} from 'interfaces/IExperience'
import axios from 'axios'
import {apiHost} from 'config/apiConfig'
import React from 'react'

export default function MRTExperience({data}: {data: IExperience[]}) {
  const columns = useMemo<MRT_ColumnDef<IExperience>[]>(
    () => [
      {
        id: 'linkedin_id',
        header: 'Linkedin Id',
        accessorKey: 'linkedin_id', //simple recommended way to define a column
        //more column options can be added here to enable/disable features, customize look and feel, etc.
        //optional custom cell render
        // Cell: ({row}) => (
        //   <Box sx={{display: 'flex', gap: '2ch', alignItems: 'center'}}>
        //     {/* <img src={row.original.imageUrl} /> */}
        //     {/* <a href={row}>{row.name}</a> */}
        //     {row}
        //   </Box>
        // ),
      },
      {
        header: 'Title',
        accessorKey: 'title', //simple recommended way to define a column
      },
      {
        id: 'start_date',
        header: 'Start Date',
        filterVariant: 'date',
        filterFn: 'lessThan',
        sortingFn: 'datetime',
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
        // accessorFn: (dataRow) => parseInt(dataRow.), //alternate way to access data if processing logic is needed
        accessorFn: (row) => new Date(row.start_date), //convert to Date for sorting and filtering
        Header: ({column}) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '250px',
          },
        },
      },
    ],
    []
  )

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: true,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
      density: 'compact',
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 30],
      shape: 'rounded',
      variant: 'outlined',
    },
    muiDetailPanelProps: ({row}) => ({
      sx: {
        width: '20px',
        bgcolor: 'pink',
      },
    }),
    muiExpandButtonProps: ({row}) => ({
      sx: {
        width: '10px',
        color: 'green',
        m: 0,
        p: 0,
      },
    }),
    renderDetailPanel: ({row}) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-around',
          left: '30px',
          maxWidth: '1000px',
          position: 'sticky',
          width: '100%',
          bgcolor: 'red',
        }}
      >
        {/* <img
        alt='avatar'
        height={200}
        src={row.original.avatar}
        loading='lazy'
        style={{borderRadius: '50%'}}
      /> */}
        <Typography>img</Typography>
        <Box sx={{textAlign: 'center'}}>
          <Typography variant='h4'>Description:</Typography>
          <Typography variant='h1'>&quot;{row.original.description}&quot;</Typography>
        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({closeMenu}) => [
      <MenuItem
        key={0}
        onClick={() => {
          // View profile logic...
          closeMenu()
        }}
        sx={{m: 0}}
      >
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        View Profile
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // Send email logic...
          closeMenu()
        }}
        sx={{m: 0}}
      >
        <ListItemIcon>
          <Send />
        </ListItemIcon>
        Send Email
      </MenuItem>,
    ],
    renderTopToolbar: ({table}) => {
      const handleDeactivate = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('deactivating ' + row.getValue('name'))
        })
      }

      const handleActivate = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('activating ' + row.getValue('name'))
        })
      }

      const handleContact = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('contact ' + row.getValue('name'))
        })
      }

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          })}
        >
          <Box sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
            {/* import MRT sub-components */}
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
          <Box>
            <Box sx={{display: 'flex', gap: '0.5rem'}}>
              <Button
                color='error'
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleDeactivate}
                variant='contained'
              >
                Deactivate
              </Button>
              <Button
                color='success'
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleActivate}
                variant='contained'
              >
                Activate
              </Button>
              <Button
                color='info'
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleContact}
                variant='contained'
              >
                Contact
              </Button>
            </Box>
          </Box>
        </Box>
      )
    },
  })

  // const Example = () => {
  //   // const columns = useMemo<MRT_ColumnDef<IExperience>[]>(
  //   //   () => [
  //   //     {
  //   //       id: 'id', //id used to define `group` column
  //   //       header: 'Student Info',
  //   //       columns: [
  //   //         {
  //   //           accessorFn: (row) => `${row.email}`, //accessorFn used to join multiple data into a single cell
  //   //           id: 'id', //id is still required when using accessorFn instead of accessorKey
  //   //           header: 'id',
  //   //           size: 250,
  //   //           Cell: ({renderedCellValue, row}) => (
  //   //             <Box
  //   //               sx={{
  //   //                 display: 'flex',
  //   //                 alignItems: 'center',
  //   //                 gap: '1rem',
  //   //               }}
  //   //             >
  //   //               {/* <img
  //   //                 alt='avatar'
  //   //                 height={30}
  //   //                 src={row.original.}
  //   //                 loading='lazy'
  //   //                 style={{borderRadius: '50%'}}
  //   //               /> */}
  //   //               <Typography>Img</Typography>
  //   //               {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
  //   //               <span>{renderedCellValue}</span>
  //   //             </Box>
  //   //           ),
  //   //         },
  //   //         {
  //   //           accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
  //   //           enableClickToCopy: true,
  //   //           filterVariant: 'autocomplete',
  //   //           header: 'Email',
  //   //           size: 300,
  //   //         },
  //   //       ],
  //   //     },
  //   //     {
  //   //       id: 'title',
  //   //       header: 'Title',
  //   //       columns: [
  //   //         {
  //   //           accessorKey: 'salary',
  //   //           // filterVariant: 'range', //if not using filter modes feature, use this instead of filterFn
  //   //           filterFn: 'between',
  //   //           header: 'Salary',
  //   //           size: 200,
  //   //           //custom conditional format and styling
  //   //           Cell: ({cell}) => (
  //   //             <Box
  //   //               component='span'
  //   //               sx={(theme) => ({
  //   //                 backgroundColor:
  //   //                   cell.getValue<number>() < 50_000
  //   //                     ? theme.palette.error.dark
  //   //                     : cell.getValue<number>() >= 50_000 && cell.getValue<number>() < 75_000
  //   //                       ? theme.palette.warning.dark
  //   //                       : theme.palette.success.dark,
  //   //                 borderRadius: '0.25rem',
  //   //                 color: '#fff',
  //   //                 maxWidth: '9ch',
  //   //                 p: '0.25rem',
  //   //               })}
  //   //             >
  //   //               {cell.getValue<number>()?.toLocaleString?.('en-US', {
  //   //                 style: 'currency',
  //   //                 currency: 'USD',
  //   //                 minimumFractionDigits: 0,
  //   //                 maximumFractionDigits: 0,
  //   //               })}
  //   //             </Box>
  //   //           ),
  //   //         },
  //   //         {
  //   //           accessorKey: 'title', //hey a simple column for once
  //   //           header: 'Job Title',
  //   //           size: 350,
  //   //         },
  //   //         {
  //   //           accessorFn: (row) => new Date(row.start_date), //convert to Date for sorting and filtering
  //   //           id: 'start_date',
  //   //           header: 'Start Date',
  //   //           filterVariant: 'date',
  //   //           filterFn: 'lessThan',
  //   //           sortingFn: 'datetime',
  //   //           Cell: ({cell}) => cell.getValue<Date>()?.toLocaleDateString(), //render Date as a string
  //   //           Header: ({column}) => <em>{column.columnDef.header}</em>, //custom header markup
  //   //           muiFilterTextFieldProps: {
  //   //             sx: {
  //   //               minWidth: '250px',
  //   //             },
  //   //           },
  //   //         },
  //   //       ],
  //   //     },
  //   //   ],
  //   //   []
  //   // )

  //   return
  // }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  )
}
