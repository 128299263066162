import axios from 'axios'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {apiHost} from 'config/apiConfig'
import React from 'react'
import Box from '@mui/material/Box'
import {DataGrid, GridColDef, GridRenderCellParams, GridToolbar} from '@mui/x-data-grid'
import {IExperience} from 'interfaces/IExperience'
import {useAuth} from 'auth/useAuth'
import ShowMoreText from './ShowMoreText'
import InteractiveLoading from './InteractiveLoading'
import {emFormatStandardDate} from 'utils/dateFormatter'

export default function ExperienceDataGrid() {
  const [, , logoutAuth] = useAuth()
  const responseGetDataGridExperience = (response: any) => {
    axios({
      method: 'GET',
      url: `${apiHost}/api/data/experiences`,
    })
      .then(async (apiResponse: any) => {
        setData(apiResponse.data as unknown as IExperience[])
      })
      .catch((err) => {
        if (err.message.includes('401')) {
          logoutAuth()
        }
        return undefined
      })
  }

  const [data, setData] = React.useState<any>(responseGetDataGridExperience)

  const columns: GridColDef<(typeof data)[number]>[] = [
    {
      field: 'id',
      headerName: 'TCF Identifier',
      width: 120,
      editable: false,
      hideable: true,
      description: 'TCF ID in database',
    },
    {
      field: 'linkedin_id',
      headerName: 'Linkedin ID',
      description: 'Linkedin identifier, comes from SCALE provided url',
      width: 350,
      editable: false,
      hideable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      description: 'Email provided by scale',
      width: 220,
      editable: false,
      hideable: true,
    },
    {
      field: 'title',
      headerName: 'Job Title',
      description: 'Job Title from Linkedin',
      width: 320,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={40} />
          </Box>
        ) : (
          <></>
        ),
      editable: false,
      hideable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      description: 'User provided description html scraped from Linkedin',
      width: 600,
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <ShowMoreText text={params.value} maxLen={80} />
          </Box>
        ) : (
          <></>
        ),
      editable: false,
      hideable: true,
    },

    {
      field: 'duration',
      headerName: 'Duration',
      description: 'Linkedin string representation of experience duration',
      width: 120,
      editable: false,
      hideable: true,
    },
    {
      field: 'location',
      headerName: 'Location',
      description: 'Linkedin Job Location',
      width: 250,
      editable: false,
      hideable: true,
    },

    {
      field: 'company_name',
      headerName: 'Company Name',
      description: 'Company Name',
      width: 300,
      editable: false,
      hideable: true,
    },
    // {
    //   field: 'company_id',
    //   headerName: 'Company ID',
    //   width: 100,
    //   editable: false,
    //   hideable: true,
    // },
    {
      field: 'company_url',
      headerName: 'Company URL',
      description: 'Company Link',
      width: 130,
      // valueGetter: (value) => {
      //   return value ? (
      //     <a href={value}>
      //       Company Url <OpenInNewIcon fontSize='small' />
      //     </a>
      //   ) : (
      //     <></>
      //   )
      // },
      renderCell: (params: GridRenderCellParams<any, string>) =>
        params.value ? (
          <Box display={'flex'} alignItems={'center'}>
            <a href={params.value}>
              Company Url <OpenInNewIcon fontSize='small' />
            </a>
          </Box>
        ) : (
          <></>
        ),
      editable: false,
      hideable: true,
    },
    {
      field: 'last_updated',
      valueGetter: (value) => {
        if (!value) {
          return value
        }
        return emFormatStandardDate(value)
      },
      headerName: 'Last Updated',
      description: 'Time identified as last updated on Linkedin',
      width: 150,
      editable: false,
      hideable: true,
    },
    {
      field: 'order_in_profile',
      headerName: 'Order',
      description: 'Linkedin Order of Experience in profile',
      width: 100,
      editable: false,
      hideable: true,
    },
    {
      field: 'date_from',
      headerName: 'Date From',
      description: 'Linkedin string date for ending position',
      width: 150,
      editable: false,
      hideable: true,
    },
    {
      field: 'date_to',
      headerName: 'Date to',
      description: 'Linkedin string date for starting position',
      width: 150,
      editable: false,
      hideable: true,
    },
    {
      field: 'start_date',
      valueGetter: (value) => {
        if (!value) {
          return value
        }
        return emFormatStandardDate(value)
      },
      headerName: 'Start Date',
      description: 'TCF generated date from Linkedin string "date_from"',
      width: 150,
      editable: false,
      hideable: true,
    },
    {
      field: 'end_date',
      valueGetter: (value) => {
        if (!value) {
          return value
        }
        return emFormatStandardDate(value)
      },
      headerName: 'End Date',
      description: 'TCF generated date from Linkedin string "date_to"',
      width: 150,
      editable: false,
      hideable: true,
    },
    {
      field: 'start_end_range',
      headerName: 'Start to End',
      description: 'Our generated date range to enable searching date ranges',
      width: 250,
      editable: false,
      hideable: true,
    },
    {
      field: 'created_dttm',
      headerName: 'TCF Created',
      description: 'Date the experience was created by TCF',
      valueGetter: (value) => {
        if (!value) {
          return value
        }
        return emFormatStandardDate(value)
      },
      width: 150,
      editable: false,
      hideable: true,
    },
    // {
    //   field: 'is_deleted',
    //   headerName: 'Deleted',
    //   width: 70,
    //   editable: false,
    //   hideable: true,
    // },
  ]

  return (
    <Box display={'flex'}>
      <Box sx={{height: 625, width: '100%'}}>
        {!data ? (
          <InteractiveLoading loading={true} success={false} />
        ) : (
          <DataGrid
            slots={{toolbar: GridToolbar}}
            getRowHeight={() => 'auto'}
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  company_name: false,
                  company_id: false,
                  company_url: false,
                  order_in_profile: false,
                  duration: false,
                  location: false,
                  start_end_range: false,
                },
              },
            }}
            pageSizeOptions={[20]}
            disableRowSelectionOnClick
            density='compact'
          />
        )}
      </Box>
    </Box>
  )
}
