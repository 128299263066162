import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import axios, {AxiosRequestConfig, InternalAxiosRequestConfig} from 'axios'
import {useAuth} from './useAuth'
import {AuthHeaders} from '../hooks/api/headers'

type AuthContextProps = {
  currentUser: any
  setCurrentUser: (user: any) => void
  logout: () => void
  authLoaded: boolean
  getAuthHeaders: () => AuthHeaders
}

const initAuthContextPropsState = {
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  authLoaded: false,
  getAuthHeaders: () => ({}),
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuthProvider = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({children}: {children: ReactNode}) => {
  const [user, loginAuth, logoutAuth, setToken] = useAuth()
  const [currentUser, setStateCurrentUser] = useState<any>(user)
  const logout = () => {
    logoutAuth()
    setCurrentUser(undefined)
  }

  const setCurrentUser = (user: any) => {
    setStateCurrentUser(user)
  }
  useMemo(() => {
    if (user) {
      setCurrentUser(user)
      axios.interceptors.request.use(
        async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
          if (user) {
            config.headers.authorization = `JWT ${localStorage.getItem('token')}`
          }
          return config
        }
      )
    }
  }, [user])

  const authLoaded = useMemo(() => {
    return localStorage.getItem('token') && user
  }, [user])

  const getAuthHeaders = useCallback(() => {
    return {Authorization: `JWT ${localStorage.getItem('token')}`}
  }, [user, authLoaded])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        getAuthHeaders,
        authLoaded,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export {AuthProvider, useAuthProvider}
