import Logout from '@mui/icons-material/Logout'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import HomeIcon from '@mui/icons-material/Home'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuthProvider} from 'auth/AuthProvider'
import {emToAbsoluteUrl} from 'utils/extensionMethods'
import {grey} from '@mui/material/colors'

export default function LandingBar() {
  const navigate = useNavigate()
  const {currentUser, logout} = useAuthProvider()

  const LoggedInNavigationBar = (): React.ReactElement => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleLogout = () => {
      logout()
    }

    const handleNav = () => {
      handleClose()
      navigate('')
    }

    return (
      <React.Fragment>
        <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
          <MenuItem onClick={() => navigate('/terms')}>
            <Box display={'flex'} gap={1}>
              <span style={{color: grey[800]}}>
                <Typography variant={'body1'}>Terms of Service</Typography>
              </span>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => navigate('/privacy')}>
            <Box display={'flex'} gap={1}>
              <span style={{color: grey[800]}}>
                <Typography variant={'body1'}>Privacy Policy</Typography>
              </span>
            </Box>
          </MenuItem>

          <Tooltip title='Account settings'>
            <IconButton
              onClick={handleClick}
              size='small'
              sx={{ml: 2}}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{width: 32, height: 32}}>M</Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
          <MenuItem onClick={() => navigate('/')}>
            <HomeIcon sx={{width: '20px', height: '20px', mr: 1.2, ml: 0.2}} color={'inherit'} />{' '}
            Home
          </MenuItem>
          <MenuItem onClick={() => navigate('/profile')}>
            <Avatar variant='circular' sx={{width: '20px', height: '20px', mr: 1.2, ml: 0.2}} />{' '}
            Profile
          </MenuItem>

          <Divider />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position='static' sx={{backgroundColor: '#ebf4fd'}}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box sx={{flexGrow: 1, display: {xs: 'flex'}}}>
              <div
                style={{
                  display: 'flex',
                  maxWidth: '188px',
                  minWidth: '148px',
                  alignItems: 'center',
                }}
              >
                <img
                  src={emToAbsoluteUrl('../logo1.png')}
                  alt='Mu logo'
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </div>
              <Typography
                variant='h6'
                noWrap
                component='a'
                href='/'
                sx={{
                  p: '1em',
                  ml: '1em',
                  display: {xs: 'flex'},
                  letterSpacing: '.5rem',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                SCALE
              </Typography>
            </Box>
            <Box gap={4} justifyContent={'end'} display={'flex'}>
              {currentUser ? <LoggedInNavigationBar /> : <></>}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}
