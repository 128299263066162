import Box from '@mui/material/Box'
import React from 'react'
import LandingBar from '../../layouts/LandingBar'
import TabPanel from 'components/TabPanel'
import MainLayout from 'layouts/MainLayout'
import Footer from 'layouts/footer'

export default function Home(): React.ReactElement {
  const Home = (): React.ReactElement => {
    return (
      <Box m={3}>
        <TabPanel />
      </Box>
    )
  }

  return (
    <MainLayout header={<LandingBar />} footer={<Footer />}>
      <Home />
    </MainLayout>
  )
}
