import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Upload from './upload'
import ExperienceDataGrid from './ExperienceDataGrid'
import {grey} from '@mui/material/colors'
import ProfilesDataGrid from './ProfilesDataGrid'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{p: 3}}>{children}</Box>}
    </div>
  )
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  interface StyledTabsProps {
    children?: React.ReactNode
    value: number
    onChange: (event: React.SyntheticEvent, newValue: number) => void
  }

  const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs {...props} TabIndicatorProps={{children: <span className='MuiTabs-indicatorSpan' />}} />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  })

  interface StyledTabProps {
    label: string
  }

  const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: '#fff',
      '&.Mui-selected': {
        color: '#9ccaf7',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    })
  )

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{bgcolor: '#0a3866'}}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label='Experiences' />
          <StyledTab label='Profiles' />
          <StyledTab label='File Upload and Processing' />
        </StyledTabs>
      </Box>

      <TabPanel value={value} index={0}>
        <Typography variant={'h5'} color={grey[700]}>
          Experiences
        </Typography>
        <ExperienceDataGrid />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant={'h5'} color={grey[700]}>
          Profiles
        </Typography>
        <ProfilesDataGrid />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Upload />
      </TabPanel>
    </Box>
  )
}
