import React, {useState} from 'react'
import MainLayout from 'layouts/MainLayout'
import LandingBar from 'layouts/LandingBar'
import Footer from 'layouts/footer'
import Box from '@mui/material/Box'
import MRTExperience from './playground_components/MRTExperience'
import MRTSimpleExample from './playground_components/MRTSimpleExample'
import {IExperience} from 'interfaces/IExperience'
import axios from 'axios'
import {apiHost} from 'config/apiConfig'
import {useAuthProvider} from 'auth/AuthProvider'
import {Card, CardContent, CardHeader, Typography} from '@mui/material'
import grey from '@mui/material/colors/grey'
import {IUser} from 'interfaces/shared'

export default function Profile(): React.ReactElement {
  const {currentUser} = useAuthProvider()
  const cu = currentUser as unknown as IUser
  return (
    <MainLayout header={<LandingBar />} footer={<Footer />}>
      <Card>
        <CardHeader
          title={
            <Typography variant={'h5'} color={grey[800]}>
              Profile
            </Typography>
          }
        />

        <CardContent>
          <Typography variant={'h6'} color={grey[900]}>
            {' '}
            {cu.email}
          </Typography>
        </CardContent>
      </Card>
    </MainLayout>
  )
}
