import React from 'react'
import FlexHeadFoot from '../../layouts/flexheadfoot'
import {GoogleOAuthProvider} from '@react-oauth/google'
import LoginForm from 'components/forms/loginForm'
import MainLayout from 'layouts/MainLayout'
import LandingBar from 'layouts/LandingBar'
import Footer from 'layouts/footer'

export default function Login(): React.ReactElement {
  return (
    <MainLayout header={<LandingBar />} footer={<Footer />}>
      {/* <GoogleOAuthProvider clientId='1014600278523-a8eh33mq00mh0ae2a3mf548cfp7bf515.apps.googleusercontent.com'> */}
      <FlexHeadFoot>
        <LoginForm />
      </FlexHeadFoot>
      {/* </GoogleOAuthProvider> */}
    </MainLayout>
  )
}
