//@ts-nocheck
import React, {useEffect, useRef, useState, useMemo} from 'react'
import Box from '@mui/material/Box'
import publications from './constants/publications.json'
import * as d3 from 'd3'

export default function NetworkVisualization() {
  const svgRef = useRef(null)
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const COLORS = useMemo(
    () => ({
      strong: '#4a90e2',
      medium: '#81b1e3',
      light: '#b8d4f0',
    }),
    []
  )

  const NODE_RADIUS = 60
  const LEGEND_WIDTH = 425
  const LEGEND_HEIGHT = 220

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    svg.selectAll('*').remove()

    svg
      .attr('width', dimensions.width)
      .attr('height', dimensions.height)
      .style('background-color', '#ffffff')

    const container = svg.append('g')

    const universities = new Set()
    const collaborationMap = new Map()
    const universityCollaborations = new Map()

    publications.forEach((pub) => {
      universities.add(pub.institution)
      universities.add(pub.collaborator_institution)

      const incrementCollaboration = (uni) => {
        const current = universityCollaborations.get(uni) || 0
        universityCollaborations.set(uni, current + 1)
      }

      incrementCollaboration(pub.institution)
      incrementCollaboration(pub.collaborator_institution)

      const key = `${pub.institution}---${pub.collaborator_institution}`
      if (!collaborationMap.has(key)) {
        collaborationMap.set(key, {
          source: pub.institution,
          target: pub.collaborator_institution,
          count: 0,
        })
      }
      collaborationMap.get(key).count++
    })

    const linkValues = Array.from(collaborationMap.values()).map((rel) => rel.count)
    const minCollabs = d3.min(linkValues)
    const maxCollabs = d3.max(linkValues)
    const range = maxCollabs - minCollabs

    const categoryBoundaries = {
      low: minCollabs,
      mid: minCollabs + range / 3,
      high: minCollabs + (2 * range) / 3,
      max: maxCollabs,
    }

    // Define collaboration categories with their styling properties
    const collaborationCategories = {
      low: {
        color: '#666666',
        width: 1.85,
        markerWidth: 6.85,
        markerHeight: 6.85,
        arrowSize: 6.85,
      },
      medium: {
        color: '#444444',
        width: 2.5,
        markerWidth: 7.5,
        markerHeight: 7.5,
        arrowSize: 7.5,
      },
      high: {
        color: '#222222',
        width: 3.15,
        markerWidth: 8.15,
        markerHeight: 8.15,
        arrowSize: 8.15,
      },
    }

    const defs = svg.append('defs')

    // Create markers for each category
    Object.entries(collaborationCategories).forEach(([type, props]) => {
      defs
        .append('marker')
        .attr('id', `arrow-${type}`)
        .attr('viewBox', `0 -5 10 10`)
        .attr('refX', type != 'high' ? props.markerWidth : props.markerWidth - 0.85) // Simplified refX
        .attr('refY', 0)
        .attr('markerWidth', props.markerWidth) // Consistent size
        .attr('markerHeight', props.markerHeight) // Consistent size
        .attr('orient', 'auto') // Changed from auto-start-reverse
        .append('path')
        .attr('d', 'M0,-4L8,0L0,4') // Simplified arrow shape
        .style('fill', props.color)
    })

    // Function to determine category based on value
    const getCollaborationCategory = (value) => {
      if (value >= categoryBoundaries.high) return 'high'
      if (value >= categoryBoundaries.mid) return 'medium'
      return 'low'
    }

    const getFillColor = (count) => {
      const color = count >= 100 ? COLORS.strong : count >= 50 ? COLORS.medium : COLORS.light
      return d3.color(color).copy({opacity: 0.3})
    }

    const nodes = Array.from(universities).map((uni) => ({
      id: uni,
      totalCollaborations: universityCollaborations.get(uni),
      fillColor: getFillColor(universityCollaborations.get(uni)),
    }))

    const links = Array.from(collaborationMap.values()).map((rel) => ({
      source: rel.source,
      target: rel.target,
      value: rel.count,
    }))

    // Enhanced tooltips
    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('background', 'white')
      .style('padding', '12px')
      .style('border', '1px solid #ddd')
      .style('border-radius', '6px')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('box-shadow', '0 4px 8px rgba(0,0,0,0.1)')
      .style('font-family', 'Arial, sans-serif')
      .style('font-size', '14px')

    const linkTooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'link-tooltip')
      .style('position', 'absolute')
      .style('background', 'white')
      .style('padding', '10px')
      .style('border', '1px solid #ddd')
      .style('border-radius', '6px')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('box-shadow', '0 4px 8px rgba(0,0,0,0.1)')
      .style('font-family', 'Arial, sans-serif')
      .style('font-size', '14px')

    const simulation = d3
      .forceSimulation(nodes)
      .force(
        'link',
        d3
          .forceLink(links)
          .id((d) => d.id)
          .strength(0.2)
      )
      .force('charge', d3.forceManyBody().strength(-2000))
      .force('center', d3.forceCenter(dimensions.width / 2, dimensions.height / 2))
      .force('collision', d3.forceCollide().radius(NODE_RADIUS + 20))

    const link = container
      .selectAll('.link')
      .data(links)
      .enter()
      .append('path')
      .attr('class', 'link')
      .style('fill', 'none')
      .style('stroke', (d) => collaborationCategories[getCollaborationCategory(d.value)].color)
      .style(
        'stroke-width',
        (d) => collaborationCategories[getCollaborationCategory(d.value)].width
      )
      .attr('marker-end', (d) => `url(#arrow-${getCollaborationCategory(d.value)})`)
      .on('mouseover', function (event, d) {
        linkTooltip.transition().duration(200).style('opacity', 0.9)
        linkTooltip
          .html(`<strong>Collaborations:</strong> ${d.value}`)
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 10 + 'px')
      })
      .on('mouseout', function () {
        linkTooltip.transition().duration(500).style('opacity', 0)
      })

    const nodeGroup = container
      .selectAll('.node-group')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', 'node-group')
      .call(d3.drag().on('start', dragstarted).on('drag', dragged).on('end', dragended))
      .on('mouseover', function (event, d) {
        tooltip.transition().duration(200).style('opacity', 0.9)
        tooltip
          .html(
            `
              <strong>${d.id}</strong><br/>
              <span style="color: #666">Total Collaborations: ${d.totalCollaborations}</span>
            `
          )
          .style('left', event.pageX + 10 + 'px')
          .style('top', event.pageY - 10 + 'px')
      })
      .on('mouseout', function () {
        tooltip.transition().duration(500).style('opacity', 0)
      })

    nodeGroup
      .append('circle')
      .attr('class', 'node')
      .attr('r', NODE_RADIUS)
      .attr('fill', (d) => d.fillColor)
      .attr('stroke', '#666')
      .attr('stroke-width', 2)

    const textGroup = nodeGroup.append('g').attr('class', 'text-group')

    textGroup.each(function (d) {
      const text = d3.select(this)
      const words = d.id.split(/\s+/)
      const lineHeight = 16
      const totalHeight = words.length * lineHeight
      const y = -totalHeight / 2 + lineHeight / 2

      words.forEach((word, i) => {
        text
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('y', y + i * lineHeight)
          .attr('x', 0)
          .text(word)
          .style('font-size', '14px')
          .style('font-family', 'Arial, sans-serif')
          .style('font-weight', '500')
      })
    })

    const legendGroup = svg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', `translate(40, 40)`)

    legendGroup
      .append('rect')
      .attr('width', LEGEND_WIDTH)
      .attr('height', LEGEND_HEIGHT)
      .style('fill', '#b8d4f0')
      .style('stroke', '#ddd')
      .style('rx', 8)
      .style('ry', 8)

    legendGroup
      .append('text')
      .attr('x', 30)
      .attr('y', 40)
      .text('Collaboration Strength')
      .style('font-weight', 'bold')
      .style('font-size', '16px')
      .style('font-family', 'Arial, sans-serif')

    const legendCategories = [
      {
        type: 'low',
        value: categoryBoundaries.low,
        range: `${Math.round(categoryBoundaries.low)}-${Math.round(categoryBoundaries.mid - 1)}`,
      },
      {
        type: 'medium',
        value: categoryBoundaries.mid,
        range: `${Math.round(categoryBoundaries.mid)}-${Math.round(categoryBoundaries.high - 1)}`,
      },
      {
        type: 'high',
        value: categoryBoundaries.high,
        range: `${Math.round(categoryBoundaries.high)}+`,
      },
    ]

    legendCategories.forEach((category, i) => {
      const yPos = 80 + i * 50
      const props = collaborationCategories[category.type]

      legendGroup
        .append('line')
        .attr('x1', 30)
        .attr('x2', 180)
        .attr('y1', yPos)
        .attr('y2', yPos)
        .style('stroke', props.color)
        .style('stroke-width', props.width) // This should now match the actual line width
        .attr('marker-end', `url(#arrow-${category.type})`)

      legendGroup
        .append('text')
        .attr('x', 240)
        .attr('y', yPos + 5)
        .text(`${category.range} collaborations`)
        .style('font-size', '14px')
        .style('font-family', 'Arial, sans-serif')
    })

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart()
      d.fx = d.x
      d.fy = d.y
    }

    function dragged(event, d) {
      d.fx = event.x
      d.fy = event.y
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0)
      d.fx = event.x
      d.fy = event.y
    }

    const dragLegend = d3
      .drag()
      .on('start', function (event) {
        const transform = d3.select(this).attr('transform')
        const translate = transform ? transform.match(/translate\(([^)]+)\)/) : null
        const [x, y] = translate ? translate[1].split(',').map(Number) : [0, 0]
        d3.select(this)
          .attr('data-x', event.x - x)
          .attr('data-y', event.y - y)
      })
      .on('drag', function (event) {
        const x = event.x - +d3.select(this).attr('data-x')
        const y = event.y - +d3.select(this).attr('data-y')
        d3.select(this).attr('transform', `translate(${x},${y})`)
      })

    legendGroup.style('cursor', 'move').call(dragLegend)

    simulation.on('tick', () => {
      nodes.forEach((node) => {
        if (node.fx === undefined) {
          node.x = Math.max(NODE_RADIUS, Math.min(dimensions.width - NODE_RADIUS, node.x))
          node.y = Math.max(NODE_RADIUS, Math.min(dimensions.height - NODE_RADIUS, node.y))
        }
      })

      // Update path creation to ensure proper line and arrow positioning
      link.attr('d', function (d) {
        // Get the direction vector
        const dx = d.target.x - d.source.x
        const dy = d.target.y - d.source.y
        const dr = Math.sqrt(dx * dx + dy * dy)

        if (dr === 0) return 'M0,0L0,0'

        // Normalize direction vector
        const unitDx = dx / dr
        const unitDy = dy / dr

        // Calculate start point (at source node edge)
        const startX = d.source.x + NODE_RADIUS * unitDx
        const startY = d.source.y + NODE_RADIUS * unitDy

        // Calculate end point (at target node edge)
        const endX = d.target.x - NODE_RADIUS * unitDx
        const endY = d.target.y - NODE_RADIUS * unitDy

        return `M${startX},${startY}L${endX},${endY}`
      })

      nodeGroup.attr('transform', (d) => `translate(${d.x},${d.y})`)
    })

    return () => {
      simulation.stop()
      tooltip.remove()
      linkTooltip.remove()
    }
  }, [dimensions, COLORS, NODE_RADIUS, LEGEND_WIDTH, LEGEND_HEIGHT])

  return (
    <Box display={'flex'}>
      <svg
        ref={svgRef}
        style={{
          width: '100vw',
          height: '100vh',
          position: 'relative',
          top: 0,
          left: 0,
        }}
      />
    </Box>
  )
}
