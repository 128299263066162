const API_PORT = 8080

class Environment {
  isLocal: boolean
  websiteDomain: string
  apiDomain: string
  protocol: string

  constructor() {
    this.isLocal = window.location.hostname.indexOf('localhost') !== -1
    this.protocol = window.location.protocol

    if (window.location.port) {
      this.websiteDomain = `${window.location.hostname}:${window.location.port}`
    } else {
      this.websiteDomain = window.location.hostname
    }

    if (this.isLocal) {
      const API_PORT = process.env.REACT_APP_API_PORT ?? 8080
      const API_HOST = process.env.REACT_APP_API_HOST ?? 'localhost'
      this.apiDomain = `${API_HOST}:${API_PORT}`
    } else {
      //TODO ... change for prodction setup and deploy
      //this.apiDomain = this.websiteDomain
      const API_PORT = process.env.REACT_APP_API_PORT ?? 8080
      const API_HOST = process.env.REACT_APP_API_HOST ?? 'localhost'
      this.apiDomain = `${API_HOST}:${API_PORT}`
    }
  }

  get apiHost() {
    return `${this.protocol}//${this.apiDomain}/api`
  }

  get websiteHost() {
    return `${this.protocol}//${this.websiteDomain}`
  }
}

function formatEndpoint(
  baseEndpoint: string,
  urlParams: string | Record<string, unknown> | undefined,
  slashAtEnd: boolean
) {
  if (baseEndpoint.slice(-1) !== '/' && slashAtEnd) {
    baseEndpoint += '/'
  } else if (baseEndpoint.slice(-1) === '/' && !slashAtEnd) {
    baseEndpoint = baseEndpoint.slice(0, baseEndpoint.length - 1)
  }
  if (urlParams !== undefined) {
    if (typeof urlParams === 'string') {
      if (urlParams.slice(0, 1) !== '?') {
        baseEndpoint += '?'
      }
      baseEndpoint += urlParams
    } else if (Object.keys(urlParams).length > 0) {
      const urlParamsHolder = new URLSearchParams()
      for (const param in urlParams) {
        urlParamsHolder.set(param, String(urlParams[param]))
      }
      baseEndpoint = `${baseEndpoint}?${urlParamsHolder.toString()}`
    }
  }
  return baseEndpoint
}

export function apiEndpoint(baseEndpoint: string, urlParams?: string | Record<string, unknown>) {
  const env = new Environment()
  const output = formatEndpoint(baseEndpoint, urlParams, true)
  return `${env.apiHost}/${output}`
}

export function websiteEndpoint(baseEndpoint: string, queryString?: string) {
  const env = new Environment()
  if (queryString !== undefined) {
    if (queryString.slice(0, 1) !== '?') {
      baseEndpoint += '?'
    }
    baseEndpoint += queryString
  }
  const output = `${env.websiteHost}/${baseEndpoint}`
  return output
}

export const apiHost = process.env.APIHOST || `${window.location.protocol}//${getApiHost()}`

//will add test, dev, prod later so setup windo.location for that
function getApiHost() {
  const env = new Environment()
  let apiHostName = ''
  if (window.location.hostname.indexOf('localhost') !== -1) {
    apiHostName = env.apiDomain
  } else {
    //AWS Ec2
    apiHostName = 'www.tcfskunkworks.com'
  }
  return apiHostName
}
