import React from 'react'

const TCF_MAIN = 'TFC_MAIN'

export type Props = React.PropsWithChildren<{
  header?: React.ReactNode
  footer?: React.ReactNode
}>

export default function MainLayout({children, header, footer}: Props): React.ReactElement {
  return (
    <>
      <div style={{backgroundColor: '#083866'}}>
        {header}
        <div style={{borderTop: '1px solid black'}}>
          <main
            style={{
              flexGrow: 1,
              minHeight: '100vh',
              overflow: 'auto',
              backgroundColor: 'white',
              maxWidth: '1536px',
              margin: 'auto',
            }}
            id={TCF_MAIN}
          >
            <div> {children}</div>
          </main>

          {footer}
        </div>
      </div>
    </>
  )
}
