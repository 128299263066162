import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'App.css'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter} from 'react-router-dom'
import {AuthProvider} from './auth/AuthProvider'
import AppRoutes from 'AppRoutes'

const queryClient = new QueryClient()
const {PUBLIC_URL} = process.env
const domNode = document.getElementById('root')

const root = ReactDOM.createRoot(domNode as HTMLElement)
root.render(
  <BrowserRouter basename={PUBLIC_URL}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>
)
