import React from 'react'
import MainLayout from 'layouts/MainLayout'
import LandingBar from 'layouts/LandingBar'
import Footer from 'layouts/footer'
import {Card, CardContent, CardHeader, Typography} from '@mui/material'
import grey from '@mui/material/colors/grey'
import GraphLayout from 'layouts/GraphLayout'
import NetworkVisualization from 'components/graphs/NetworkVisualization'

export default function GraphDemo2(): React.ReactElement {
  return (
    <GraphLayout header={<LandingBar />} footer={<Footer />}>
      <NetworkVisualization />
    </GraphLayout>
  )
}
