import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import {grey} from '@mui/material/colors'
import {useState} from 'react'

export default function ShowMoreText({
  text,
  maxLen,
  moreString,
  lessString,
  emptyCondition,
  variant,
  dialog,
  inheritColor,
  noTypography,
  fontSize,
  lineHeight,
  textParagraphArr,
}: {
  text: string
  maxLen: number
  moreString?: string
  lessString?: string
  emptyCondition?: string
  variant?:
    | 'h6'
    | 'h5'
    | 'h4'
    | 'h3'
    | 'h2'
    | 'h1'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'subtitle1'
    | 'subtitle2'
    | 'button'
    | 'inherit'
    | 'overline'
  dialog?: boolean
  inheritColor?: boolean
  noTypography?: boolean
  fontSize?: string
  lineHeight?: number
  textParagraphArr?: string[]
}): React.ReactElement {
  const [isCollapse, setIsCollapse] = useState(true)
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const DialogComponent = ({inText}: {inText: string; textParagraphArr?: string[]}) => {
    const subText = text.substring(0, isCollapse ? maxLen : undefined)
    return (
      <>
        {subText}
        <a onClick={handleOpen}>
          {!open && <ReadMoreIcon color={'info'} fontSize='medium' sx={{cursor: 'pointer'}} />}
        </a>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <DialogContent>
            <Box>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Current Search Input:
              </Typography>
              {inText && !textParagraphArr ? (
                <Typography
                  id='modal-modal-description'
                  sx={{mt: 2}}
                  variant={variant}
                  fontSize={fontSize ? fontSize : ''}
                >
                  {inText}
                </Typography>
              ) : (
                textParagraphArr?.map((p) => {
                  return (
                    <Typography
                      id='modal-modal-description'
                      sx={{mt: 2}}
                      variant={variant}
                      fontSize={fontSize ? fontSize : ''}
                    >
                      {p}
                    </Typography>
                  )
                })
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return noTypography ? (
    text && text.length > maxLen ? (
      <Box
        component={'span'}
        color={inheritColor ? 'inherit' : grey[800]}
        fontSize={'inherit'}
        lineHeight={lineHeight}
      >
        {dialog ? (
          <DialogComponent inText={text} textParagraphArr={textParagraphArr} />
        ) : (
          <Box display={'flex'} flexDirection={'column'} gap={1} fontSize={'14px'}>
            {text && !textParagraphArr && text.substring(0, isCollapse ? maxLen : undefined)}
            {/* for beginning line, for read more, read less */}
            {textParagraphArr &&
              textParagraphArr.length &&
              isCollapse &&
              text.substring(0, isCollapse ? textParagraphArr[0].length : undefined)}
            {textParagraphArr &&
              textParagraphArr.length &&
              !isCollapse &&
              textParagraphArr.map((p, i) => {
                return <p key={`${i}p`}>{p}</p>
              })}
            <a onClick={() => setIsCollapse((isCollapse) => !isCollapse)}>
              {isCollapse
                ? (moreString ?? (
                    <ReadMoreIcon color={'info'} fontSize='small' sx={{cursor: 'pointer'}} />
                  ))
                : (lessString ?? (
                    <ExpandLessIcon color={'info'} fontSize='small' sx={{cursor: 'pointer'}} />
                  ))}
            </a>
          </Box>
        )}
      </Box>
    ) : (
      <Box
        component={'span'}
        color={inheritColor ? 'inherit' : grey[800]}
        fontSize={'inherit'}
        lineHeight={lineHeight}
      >
        {emptyCondition && text.includes(emptyCondition) ? <></> : text}
      </Box>
    )
  ) : text && text.length > maxLen ? (
    <>
      {dialog ? (
        <DialogComponent inText={text} textParagraphArr={textParagraphArr} />
      ) : (
        <>
          {text && !textParagraphArr ? (
            <Typography
              variant={variant}
              color={inheritColor ? 'inherit' : grey[800]}
              fontSize={fontSize ? fontSize : ''}
              lineHeight={lineHeight ? lineHeight : 1}
              display={'flex'}
              justifyContent={'space-between'}
            >
              {text && !textParagraphArr && text.substring(0, isCollapse ? maxLen : undefined)}

              <a
                onClick={() => setIsCollapse((isCollapse) => !isCollapse)}
                style={{display: 'flex', justifyContent: 'space-between'}}
              >
                {isCollapse
                  ? (moreString ?? (
                      <ReadMoreIcon color={'info'} fontSize='small' sx={{cursor: 'pointer'}} />
                    ))
                  : (lessString ?? (
                      <ExpandLessIcon color={'info'} fontSize='small' sx={{cursor: 'pointer'}} />
                    ))}
              </a>
            </Typography>
          ) : (
            <Box display={'flex'} flexDirection={'column'} gap={1} fontSize={'14px'}>
              {/* for beginning line, for read more, read less */}
              {textParagraphArr &&
                textParagraphArr.length &&
                isCollapse &&
                (textParagraphArr[0].length
                  ? textParagraphArr[0].substring(
                      0,
                      isCollapse ? textParagraphArr[0].length : undefined
                    )
                  : textParagraphArr[1].length
                    ? textParagraphArr[1].substring(
                        0,
                        isCollapse ? textParagraphArr[1].length : undefined
                      )
                    : undefined)}
              <a onClick={() => setIsCollapse((isCollapse) => !isCollapse)}>
                {isCollapse
                  ? (moreString ?? (
                      <ReadMoreIcon color={'info'} fontSize='small' sx={{cursor: 'pointer'}} />
                    ))
                  : (lessString ?? (
                      <ExpandLessIcon color={'info'} fontSize='small' sx={{cursor: 'pointer'}} />
                    ))}
              </a>
              {textParagraphArr &&
                textParagraphArr.length &&
                !isCollapse &&
                textParagraphArr.map((p, i) => {
                  if (p.length)
                    return (
                      <Typography
                        variant={variant}
                        color={inheritColor ? 'inherit' : grey[800]}
                        fontSize={fontSize ? fontSize : ''}
                        lineHeight={lineHeight}
                        key={`${i}p`}
                      >
                        {p}
                      </Typography>
                    )
                })}
            </Box>
          )}
        </>
      )}
    </>
  ) : (
    <>
      {emptyCondition && text.includes(emptyCondition) ? (
        <></>
      ) : !textParagraphArr ? (
        <Typography
          variant={variant}
          color={inheritColor ? 'inherit' : grey[800]}
          fontSize={fontSize ? fontSize : ''}
          lineHeight={lineHeight}
        >
          {text}
        </Typography>
      ) : textParagraphArr && textParagraphArr.length ? (
        <Box fontSize={'14px'}>
          {textParagraphArr.map((p, i) => {
            return <p key={`${i}p`}>{p}</p>
          })}
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}
