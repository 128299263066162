import Box from '@mui/material/Box'
import grey from '@mui/material/colors/grey'
import Typography from '@mui/material/Typography'

export default function Privacy() {
  return (
    <div style={{height: '100vh', textAlign: 'center', padding: '15px'}}>
      <Typography variant={'h4'} color={grey[700]} p={2}>
        TCF Privacy Policy
      </Typography>
      <iframe
        src={`${process.env.PUBLIC_URL}/privacy.html`}
        title='TCF Privacy Policy'
        width='95%'
        height='95%'
      />
    </div>
  )
}
